import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import isEqual from 'lodash/isEqual'
import Pricing from '@/inquiries/models/pricing'
import { PricingType } from '@/inquiries/models/pricing-type'

@Component
export default class PricingInput extends Vue {

  @Prop({ required: true })
  private value!: Pricing
  
  @Prop(String) 
  readonly rules!: string;

  @Prop(String) 
  readonly name!: string;

  private items = [
    {
      text: 'pauschal netto Euro',
      value: PricingType.AllIn
    },

    {
      text: 'Abrechnung nach Zeitaufwand netto Euro pro Stunde',
      value: PricingType.PerHour
    },
  ]

  private pricing: Pricing = this.value

  @Watch('value', { deep: true })
  private valueChanged(val: Pricing, old: Pricing) {
    if (isEqual(val, old) === false) {
      this.pricing = val
    }
  }

  @Watch('pricing', { deep: true })
  private pricingChanged(val: Pricing, old: Pricing) {
    this.$emit('input', val)
  }
}
