import Vue from 'vue'
import '@/assets/styles/app.scss'

import store from '@/app/store'
// Recovers the store from the local storage.
store.commit('init')

import '@/app/filters/index'
import '@/app/directives/index'
import '@/app/plugins/index'

import vuetify from '@/app/plugins/vuetify'
import router from '@/app/router'

import './registry'
import './registerServiceWorker'

Vue.config.productionTip = false

// This is potentially bad, but serializing a moment object to JSON results in an
// implicit conversion to UTC, which is undesired here. Overwriting the
// toJSON method and calling the ISO conversion without UTC conversion prevents this.
import moment from 'moment'
moment.prototype.toJSON = function () { return moment(this).toISOString(true) }

console.log(`NODE_ENV: ${process.env.NODE_ENV}`)
console.log(`VUE_APP_API_URL: ${process.env.VUE_APP_API_URL}`)

import App from '@/app/views/app.vue'

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
