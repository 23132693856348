import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator"
import PagedMixin from "@/common/pagination/mixins/paged-mixin"
import Inquiry from "@/inquiries/models/inquiry"
import InquiryContact from "@/inquiries/models/Inquiry-contact"

@Component
export default class InquiriesList extends Mixins(PagedMixin) {
  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public inquiries!: Inquiry[]

  @Prop({ default: () => [] })
  public pendingContacts!: InquiryContact[]

  @Prop({ type: Boolean, default: false })
  public showContacts!: boolean

  acceptContact(inquiry: Inquiry, contact: InquiryContact) {
    this.$emit("accept", inquiry, contact)
  }

  get headers() {
    return [
      { text: "Name", value: "name", sortable: false },
      { text: "E-Mail", value: "email" },
      { text: "Adresse", value: "address", sortable: false },
      { text: "Funktion", value: "role" },
      {
        text: "",
        value: "action",
        sortable: false,
        class: "td-w-shrink",
        align: "end",
      },
    ]
  }
}
