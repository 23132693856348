import moment, { duration, Moment } from 'moment'

export default class Dates {

  public static readonly weeks = [
    'Mo',
    'Di',
    'Mi',
    'Do',
    'Fr',
    'Sa',
    'So',
  ]

  public static readonly months = [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ]

  public static getWeekdayName(v: moment.MomentInput) {
    const id = moment(v).isoWeekday()
    return 1 <= id && id <= 7 ? this.weeks[id - 1] : ''
  }

  public static getMonthName(id: number) {
    return 1 <= id && id <= 12 ? this.months[id - 1] : ''
  }

  public static day(v: moment.MomentInput) {
    return moment(v).format('DD')
  }

  public static date(v: moment.MomentInput) {
    return moment(v).format('DD.MM.YYYY')
  }

  public static dateTime(v: moment.MomentInput) {
    return moment(v).format('DD.MM.YYYY HH:mm')
  }

  public static time(v: moment.MomentInput, seconds: boolean = false) {
    return moment(v).format(seconds ? 'HH:mm:ss' : 'HH:mm')
  }

  public static combine(date: string, time: string) {
    return moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm')
  }

  public static minutesAsDuration(dur: number) {
    // @ts-ignore
    return duration().add(dur, 'minutes').format('HH:mm:ss')
  }
}
