import ServiceResult from "@/common/models/service-result"
import authService from "@/users/services/auth-service"
import { Component, Vue, Ref } from "vue-property-decorator"
import snacks from "@/app/services/snacks"

@Component
export default class Login extends Vue {
  private showResetForm = false

  private reset(res: ServiceResult) {
    if (res.success) {
      if (res.message) {
        snacks.success(res.message)
      }
      this.showResetForm = false
    } else {
      snacks.error(res.message)
    }
  }
}
