import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import inquiriesService from '@/inquiries/services/inquiries-service'
import InquiryDialog from '@/inquiries/components/inquiry-dialog/inquiry-dialog'
import Inquiry from '@/inquiries/models/inquiry'

@Component
export default class SideMenu extends Vue {

  @Prop()
  private value!: boolean

  @Prop({ type: Number, default: 300 })
  private width!: number

  @Ref()
  private dialog!: InquiryDialog

  private show = true
  private pValue: boolean = false

  private togglePermanent: boolean = true
  private feedbackDialog: boolean = false

  @Watch('value')
  private valueChanged(newVal: boolean, oldVal: boolean) {
    if (newVal !== oldVal) {
      this.pValue = newVal
    }
  }

  @Watch('pValue')
  private pValueChanged(newVal: boolean, oldVal: boolean) {
    if (newVal !== oldVal) {
      this.$emit('input', newVal)
    }
  }

  get widthValue() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return '100%'
    }
    if (this.show) {
      return this.width
    }
    return 0
  }

  private toggleSideMenu() {
    this.pValue = !this.pValue
    this.$bus.$emit("sidemenu:toggle")
  }

  private showFeedbackDialog() {
    this.feedbackDialog = true
  }

  private openNew() {
    const inquiry = new Inquiry()
    inquiry.creatorId = this.$auth.user
    this.dialog.openNew(inquiry)
  }

  private async create(inquiry: Inquiry) {
    await inquiriesService.saveAsync(inquiry)
    this.$router.push('/inquiries-mine')
    this.$bus.$emit('inquiries-mine:reload')
  }

  private async mounted() {
    this.pValue = this.value
  }
}
