import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class App extends Vue {

  @Prop({ default: false })
  public value!: boolean

  @Prop({ default: '' })
  public msg!: string

  public close() {
    this.$emit('input', false)
  }
}
