import { render, staticRenderFns } from "./app.vue?vue&type=template&id=502fd29a&"
import script from "./app.ts?vue&type=script&lang=js&"
export * from "./app.ts?vue&type=script&lang=js&"
import style0 from "./app.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VApp,VBtn,VFooter,VImg,VSnackbar})
