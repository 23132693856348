import bus from '@/app/bus'
import router from '@/app/router'
import snacks from '@/app/services/snacks'
import store from '@/app/store'
// TODO: Ist das der richtige Ort für Refreshresponse oder soll das in den app ordner?
import RefreshResponse from '@/users/responses/refresh-response'
import axios from 'axios'

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  baseURL: process.env.VUE_APP_API_URL,
})

// Intercepts each axios request and adds the JWT bearer token if present.
instance.interceptors.request.use((request) => {
  const token = store.getters.token
  const tenant = store.getters.tenant
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`
  }
  // Append the user's tenant to the request header. The backend will only
  // return entities that belong to this tenant.
  if (tenant) {
    request.headers['Tenant'] = tenant
  }
  return request
})

// Intercepts Token-refresh responses.
// https://www.blinkingcaret.com/2018/05/30/refresh-tokens-in-asp-net-core-web-api/
// https://github.com/Flyrell/axios-auth-refresh/blob/master/src/index.js
// https://www.techynovice.com/setting-up-JWT-token-refresh-mechanism-with-axios/
function createRefreshInterceptor() {
  const id = instance.interceptors.response.use((response) => response, (error) => {
    if (error.response && error.response.headers.hasOwnProperty('token-expired')) {
      const token = store.getters.token
      const refreshToken = store.getters.refreshToken

      // Removes itself to evade infinite loops due to token refresh 401.
      instance.interceptors.response.eject(id)

      const call = instance
        .post<RefreshResponse>('auth/refresh', {
          token,
          refreshToken,
        })
        .then((res) => {
          if (res.data.success) {
            store.commit('refresh', res.data)
          } else {
            store.commit('logout')
            router.push('login')
          }
        })

      // Create interceptor that will bind all the other requests until
      // refresh token call is resolved.
      const queueInterceptorId = instance.interceptors.request.use((request) =>
        call.then(() => request))

      return call
        .then(() => {
          instance.interceptors.request.eject(queueInterceptorId)
          return instance(error.response.config)
        })
        .catch((err) => {
          instance.interceptors.request.eject(queueInterceptorId)
          return Promise.reject(err)
        })
        .finally(() =>
          createRefreshInterceptor())
    } else if (error instanceof Error) {
      // @ts-ignore
      const response = error.response
      let msg = error.message
      if (response) {
        if (response.data) {
          msg = response.data
          if (response.data.title) {
            msg = response.data.title
          }
        }
      }
      if (process.env.NODE_ENV === 'development' && response) {
        if (response.status >= 500) {
          bus.$emit('app.exception-dialog.show', msg)
        } else {
          snacks.error(msg)
        }
      } else {
        snacks.error(msg)
      }
    }
  })
}

createRefreshInterceptor()

export default instance
