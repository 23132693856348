













import { Component, Vue } from "vue-property-decorator";
import moment from 'moment'

@Component
export default class Demo extends Vue {
  private time1 = moment();
}
