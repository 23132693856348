import { Component, Ref, Vue, Watch, Prop } from 'vue-property-decorator'
import Inquiry from '@/inquiries/models/inquiry'
import inquiriesService from '@/inquiries/services/inquiries-service'
import InquiryDialog from '@/inquiries/components/inquiry-dialog/inquiry-dialog'
import InquiriesFilterRequest from '@/inquiries/models/inquiries-filter-request'
import { InquiryState } from '@/inquiries/models/inquiry-state'

@Component
export default class InquiriesMine extends Vue {

  @Ref()
  private dialog!: InquiryDialog

  private loading: boolean = false
  private inquiries: Inquiry[] = []
  private filter: InquiriesFilterRequest = new InquiriesFilterRequest()

  private async findAsync() {
    this.loading = true
    const response = await inquiriesService.findAllAsync(this.filter)
    this.inquiries = response.items
    this.filter.pagination.totalItems = response.totalItems
    this.loading = false
  }

  private openNew() {
    const inquiry = new Inquiry()
    inquiry.creatorId = this.$auth.user
    this.dialog.openNew(inquiry)
  }

  private openEdit(inquiry: Inquiry) {
    this.dialog.openEdit(inquiry)
  }

  private async create(inquiry: Inquiry) {
    await inquiriesService.saveAsync(inquiry)
    await this.findAsync()
  }

  private async update(inquiry: Inquiry) {
    await inquiriesService.updateAsync(inquiry)
    await this.findAsync()
  }

  private async contact(inquiry: Inquiry) {
    if (confirm('Der Anbieter wird jetzt per Mail von Ihrem Interesse informiert, und wird sich dann ggf. bei Ihnen melden. Möchten Sie fortfahren?')) {
      await inquiriesService.contactAsync(this.$auth.user, inquiry)
      await this.findAsync()
    }
  }

  private async close(inquiry: Inquiry) {
    if (confirm('Wirklich abschließen?')) {
      await inquiriesService.closeAsync(inquiry)
      await this.findAsync()
    }
  }

  private async reactivate(inquiry: Inquiry) {
    await inquiriesService.reactivateAsync(inquiry)
    await this.findAsync()
  }

  private async remove(inquiry: Inquiry) {
    if (confirm('Wirklich löschen?')) {
      await inquiriesService.removeAsync(inquiry.id)
      await this.findAsync()
    }
  }

  private async mounted() {
    this.filter.creatorId = null
    this.filter.takerId = this.$auth.user
    this.filter.state = InquiryState.OpenContactedNegotiating
    this.filter.radiusOfInterest = 0
    this.filter.includeContacts = true
    await this.findAsync()
  }
}
