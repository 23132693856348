import store from '@/app/store'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import User from '@/users/entities/user'

@Module({ store, name: 'AccountModule' })
export default class AccountModule extends VuexModule {

  public data: User | null = null

  get userData(): User {
    return this.data || new User()
  }

  @Mutation
  public setUserData(data: User | null) {
    this.data = data
  }
}
