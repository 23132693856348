















































import { Component, Ref, Vue } from "vue-property-decorator"

@Component
export default class Home extends Vue {
  @Ref()
  private videoPlayer!: HTMLVideoElement
  @Ref()
  private overlayDiv!: HTMLDivElement
  @Ref()
  private playButton!: HTMLImageElement

  private restartVideo() {
    this.videoPlayer.muted = false
    this.videoPlayer.currentTime = 0
    this.videoPlayer.play()
  }

  private mounted() {
    // this.videoPlayer.onvolumechange = (e) => {
    //   if (this.videoPlayer.muted === false) {
    //     this.restartVideo()
    //   }
    // }

    this.overlayDiv.onclick = (e) => {
      this.playButton.hidden = true
      this.overlayDiv.remove()
      this.playButton.remove()
      this.restartVideo()
    }
  }
}
