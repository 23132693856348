







import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component
export default class SimpleHeader extends Vue {

  @Prop()
  private title!: string
}
