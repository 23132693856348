import cloneDeep from "lodash/cloneDeep"
import { Component, Prop, Vue, Ref } from "vue-property-decorator"

@Component
export default class EditDialogMixin<T> extends Vue {
  /**
   * You need to add a validation observer to your dialog and add the attribute
   * `ref="valdiator"`.
   */
  @Ref()
  protected validator!: ValidationObserver

  protected dialog = false
  protected loading = false
  protected editMode = false
  protected model!: T

  public cancel() {
    this.$emit("cancel")
    this.dialog = false
  }

  public close() {
    this.dialog = false
  }

  public openNew(model: T) {
    this.openInternal(false, model)
  }

  public openEdit(model: T) {
    this.openInternal(true, model)
  }

  protected async confirm() {
    this.beforeConfirm()
    if (await this.validator.validate()) {
      if (await this.validate()) {
        this.loading = true
        await this.afterValidationSuccess()
        this.emitConfirm()
        this.close()
      }
    }
  }

  protected beforeConfirm() {}

  protected async validate(): Promise<Boolean> {
    return true
  }

  protected async afterValidationSuccess() {}

  protected emitConfirm() {
    this.$emit(this.editMode ? "update" : "create", this.model)
  }

  private openInternal(editMode: boolean, model: T) {
    this.loading = false
    this.editMode = editMode
    this.model = cloneDeep(model)
    this.dialog = true
    this.reset()
  }

  private reset() {
    // For some odd reason, the validator is not ready upon first invocation
    // of the dialog.
    if (this.validator) {
      this.validator.reset()
    }
  }
}
