import User from '@/users/entities/user'
import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import EditDialogMixin from '@/common/dialogs/mixins/edit-dialog-mixin'
import AddressInput from '@/locations/components/address-input/address-input'

@Component
export default class UserDialog extends Mixins<EditDialogMixin<User>>(EditDialogMixin) {

  @Ref()
  private addressInput!: AddressInput

  get roles() {
    const rs: Array<{ value: string, text: string }> = []
    if (this.$auth.isAdmin) {
      rs.push({ value: 'ADMIN', text: 'Admin' })
    }
    rs.push({ value: 'TENANT', text: 'Mandant' })
    rs.push({ value: 'USER', text: 'Benutzer' })
    return rs
  }

  protected async afterValidationSuccess() {
    return this.addressInput.locate()
  }
}
