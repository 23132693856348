var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{staticStyle:{"margin-bottom":"75px"}},[_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-Mail","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('salutation-select',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Anrede"},model:{value:(_vm.data.salutation),callback:function ($$v) {_vm.$set(_vm.data, "salutation", $$v)},expression:"data.salutation"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('academic-title-select',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Titel"},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Vorname","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.data.firstName),callback:function ($$v) {_vm.$set(_vm.data, "firstName", $$v)},expression:"data.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nachname","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.data.lastName),callback:function ($$v) {_vm.$set(_vm.data, "lastName", $$v)},expression:"data.lastName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"companyName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Firmenwortlaut (optional)","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.data.companyName),callback:function ($$v) {_vm.$set(_vm.data, "companyName", $$v)},expression:"data.companyName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('address-input',{ref:"addressInput",attrs:{"outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.data.address),callback:function ($$v) {_vm.$set(_vm.data, "address", $$v)},expression:"data.address"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"","name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Anwaltscode","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.data.code),callback:function ($$v) {_vm.$set(_vm.data, "code", $$v)},expression:"data.code"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"outlined":""}},[_c('div',{staticClass:"title"},[_vm._v("Suchradius")]),_c('div',{staticClass:"mb-4",staticStyle:{"font-size":"85%"}},[_vm._v(" Radius in meinem Umkreis, in welchem mir (ortsgebundene) Gesuche anderer Kollegen/innen angezeigt werden. ")]),_c('validation-provider',{attrs:{"rules":"required|numeric|min_value:1","name":"radiusOfInterest"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Suchradius","outlined":"","dense":"","suffix":"km","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.data.radiusOfInterest),callback:function ($$v) {_vm.$set(_vm.data, "radiusOfInterest", _vm._n($$v))},expression:"data.radiusOfInterest"}})]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Ich möchte über neue Gesuche via E-Mail informiert werden","dense":""},model:{value:(_vm.data.automaticNewsfeedEnabled),callback:function ($$v) {_vm.$set(_vm.data, "automaticNewsfeedEnabled", $$v)},expression:"data.automaticNewsfeedEnabled"}})],1)],1),_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-btn',{attrs:{"loading":_vm.sendingReset,"disabled":_vm.loading,"depressed":"","color":"primary"},on:{"click":_vm.resetPassword}},[_vm._v(" Passwort ändern ")]),_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":!valid || _vm.sendingReset,"depressed":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" Übernehmen ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }