import Address from '@/locations/models/address'
import Entity from '@/common/models/entity'
import { InquiryState } from '@/inquiries/models/inquiry-state'
import Pricing from '@/inquiries/models/pricing'
import moment from 'moment'
import Period from '@/common/models/periods/period'
import InquiryContact from './Inquiry-contact'

export default class Inquiry extends Entity {
  public creatorId: string = ''
  public creationDate: string = moment().format('YYYY-MM-DD')
  public takerId: string | null = null
  public state: InquiryState = InquiryState.Open
  public title: string = ''
  public description: string = ''
  public expiryDate: string = moment().add(7, 'days').format('YYYY-MM-DD')
  public pricing: Pricing = new Pricing()
  public hasLocation: boolean = true
  public address: Address = new Address()
  public period: Period = new Period()
  public contacts: InquiryContact[] = []
}
