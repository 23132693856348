var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card',{staticClass:"d-flex flex-column",attrs:{"min-height":"100%"}},[_c('v-card-title',{staticStyle:{"height":"64px"}},[(_vm.editMode)?_c('span',[_vm._v("Benutzer bearbeiten")]):_c('span',[_vm._v("Benutzer hinzufügen")])]),_c('v-card-text',{staticClass:"flex-grow-1"},[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('salutation-select',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(_vm.model.salutation),callback:function ($$v) {_vm.$set(_vm.model, "salutation", $$v)},expression:"model.salutation"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('academic-title-select',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Vorname","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.firstName),callback:function ($$v) {_vm.$set(_vm.model, "firstName", $$v)},expression:"model.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nachname","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.lastName),callback:function ($$v) {_vm.$set(_vm.model, "lastName", $$v)},expression:"model.lastName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"companyName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Firmenwortlaut (optional)","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.companyName),callback:function ($$v) {_vm.$set(_vm.model, "companyName", $$v)},expression:"model.companyName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.$auth.isInRole('ADMIN') === false,"items":_vm.roles,"label":"Benutzerrolle","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.role),callback:function ($$v) {_vm.$set(_vm.model, "role", $$v)},expression:"model.role"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-Mail Adresse","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('address-input',{ref:"addressInput",attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"","name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Anwaltscode","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required|numeric|min_value:1","name":"radiusOfInterest"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Suchradius","outlined":"","dense":"","suffix":"km","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.radiusOfInterest),callback:function ($$v) {_vm.$set(_vm.model, "radiusOfInterest", _vm._n($$v))},expression:"model.radiusOfInterest"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"ich möchte über neue Gesuche via E-Mail informiert werden","dense":""},model:{value:(_vm.model.automaticNewsfeedEnabled),callback:function ($$v) {_vm.$set(_vm.model, "automaticNewsfeedEnabled", $$v)},expression:"model.automaticNewsfeedEnabled"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-7 pb-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"disabled":!valid,"loading":_vm.loading,"depressed":"","color":"primary"},on:{"click":_vm.confirm}},[(_vm.editMode)?_c('span',[_vm._v("Übernehmen")]):_c('span',[_vm._v("Erstellen")])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }