import Vue from 'vue'
import { PluginObject, VueConstructor } from 'vue'

declare module 'vue/types/vue' {
    interface Vue {
        $env: any
    }
}

class VueEnvPlugin implements PluginObject<any> {

    public install(vue: VueConstructor, options?: any): void {

        vue.prototype.$env = process.env
    }
}

Vue.use(new VueEnvPlugin())
