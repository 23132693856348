import authService from '@/users/services/auth-service'
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import snacks from '@/app/services/snacks'

@Component
export default class ResetPassword extends Vue {

  @Prop(String)
  private email!: string

  @Prop(String)
  private token!: string

  @Ref()
  private validator!: ValidationObserver

  private password = ''
  private passwordConfirmation = ''
  private serverError = ''
  private loggingIn = false

  public async resetPassword() {
    this.loggingIn = true

    const res = await authService
      .resetPasswordAsync(this.email, this.token, this.password)

    this.loggingIn = false

    if (res.success) {
      snacks.success(res.message)
      this.$router.push('/login')
    } else {
      this.serverError = res.message
    }
  }
}
