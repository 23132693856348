import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import PermissionDialog from '@/users/components/permission-dialog/permission-dialog'
import PermissionAssignment from '@/users/entities/permission-assignment'
import permissionsService from '@/users/services/permissions-service'
import Permission from '@/users/entities/permission'


@Component
export default class Permissions extends Vue {

  @Ref()
  private dialog!: PermissionDialog

  private loading: boolean = false
  private pas: PermissionAssignment = new PermissionAssignment()

  get roles() {
    return Object.keys(this.pas.assignments)
  }

  private async fetchAll() {
    this.loading = true
    this.pas = await permissionsService.findAllAsync() || new PermissionAssignment()
    this.loading = false
  }

  private create() {
    this.dialog.openNew(new Permission())
  }

  private edit(permission: Permission) {
    this.dialog.openEdit(permission)
  }

  private async save(permission: Permission) {
    await permissionsService.createAsync(permission)
    await this.fetchAll()
  }

  private async update(permission: Permission) {
    await permissionsService.updateAsync(permission)
    await this.fetchAll()
  }

  private isAssigned(role: string, permission: string) {
    const assignments = this.pas.assignments[role] || []
    return assignments.includes(permission)
  }

  private async toggleAssignment(role: string, permission: string) {
    if (this.isAssigned(role, permission)) {
      await permissionsService.removeAssignmentAsync(role, permission)
    } else {
      await permissionsService.addAssignmentAsync(role, permission)
    }
    await this.fetchAll()
  }

  private async mounted() {
    await this.fetchAll()
  }
}
