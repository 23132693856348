import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RoleBadge extends Vue {

  @Prop({ required: true })
  private role!: string

  get roleName() {
    switch (this.role) {
      case 'ADMIN': return 'Admin'
      case 'TENANT': return 'Mandant'
      case 'USER': return 'Benutzer'
      default: return ''
    }
  }
}
