import axios from '@/app/axios'
import CrudService from '@/common/services/crud-service'
import Tenant from '@/tenants/entities/tenant'
import Responses from '@/app/utils/responses'

class TenantsService extends CrudService<Tenant> {
    public async updateIsSmallBusiness(id: string, value: boolean) {
        const url = `${this.resource}/updateIsSmallBusiness`
        await axios.put(url, value)
    }

    public async uploadLogo(file: File) {
        const url = `${this.resource}/uploadLogo`
        const formData = new FormData()
        formData.append('file', file, file.name)
        await axios.post(url, formData)
    }

    public async removeLogo() {
        const url = `${this.resource}/removeLogo`
        await axios.post(url)
    }

    public async getCurrent() {
        const url = `${this.resource}/current`
        const res = await axios.get<Tenant>(url)
        return Responses.obj(res)
    }

    public async updateCurrent(model: Tenant) {
        const res = await axios.put<Tenant>(`${this.resource}/updateCurrent`, model)
        return Responses.obj(res)
    }

    public async sendFeedback(text: string) {
        await axios.post(`${this.resource}/sendFeedback`, { text: text })
    }
}

export default new TenantsService('tenants')
