import Vue from 'vue'

Vue.filter('money', (v: number, currency: string = '€') => {
  if (!v) {
    return '-'
  }
  const formatter = new Intl.NumberFormat('de', {
    style: 'currency',
    currency: 'EUR',
  })
  return `${formatter.format(v)}`
})
