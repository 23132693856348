import { Component, Ref, Vue, Watch, Prop } from 'vue-property-decorator'
import Inquiry from '@/inquiries/models/inquiry'
import inquiriesService from '@/inquiries/services/inquiries-service'
import InquiriesFilterRequest from '@/inquiries/models/inquiries-filter-request'
import { InquiryState } from '@/inquiries/models/inquiry-state'

@Component
export default class InquiriesClosed extends Vue {

  private loading: boolean = false
  private inquiries: Inquiry[] = []
  private filter = new InquiriesFilterRequest()

  private async findAsync() {
    this.loading = true
    // Workaround for empty radiusOfInterest.
    if (!this.filter.radiusOfInterest) {
      this.filter.radiusOfInterest = 0
    }
    const response = await inquiriesService.findAllClosedAsync(this.filter)
    this.inquiries = response.items
    this.filter.pagination.totalItems = response.totalItems
    this.loading = false
  }

  private async remove(inquiry: Inquiry) {
    if (confirm('Wirklich löschen?')) {
      await inquiriesService.removeAsync(inquiry.id)
      await this.findAsync()
    }
  }

  private async mounted() {
    this.filter.creatorId = null
    this.filter.takerId = null
    this.filter.state = InquiryState.Closed
    this.filter.radiusOfInterest = 0
    await this.findAsync()
  }
}
