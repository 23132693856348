import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import tenantsService from '@/tenants/services/tenants-service'

@Component
export default class FeedbackDialog extends Vue {
    @Prop()
    private value!: boolean

    @Watch('value')
    private valueChanged(newValue: boolean) {
        if (newValue) {
            this.confirmation = false
        }
    }

    private text = ''
    private confirmation = false
    private loading = false

    private close() {
        this.$emit('close')
    }

    private async send() {
        this.loading = true
        await tenantsService.sendFeedback(this.text)
        this.$emit('close')
        this.confirmation = true
        this.text = ''
        this.loading = false
    }
}