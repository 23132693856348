import moment, { Moment } from 'moment'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component
export default class TimeInput extends Vue {

  @Ref()
  private pickerMenu!: any

  @Prop({ required: true })
  private value!: moment.MomentInput

  @Prop({ default: 'HH:mm' })
  private format!: string

  private menu = false
  private model = this.formatTime(this.value)

  @Watch('value')
  private valueChanged(val: moment.MomentInput) {
    this.model = this.formatTime(val)
  }

  @Watch('model')
  private modelChanged() {
    this.$emit('input', this.model)
  }

  private formatTime(val: moment.MomentInput) {
    return moment(val, this.format).format(this.format)
  }
}
