import moment, { Moment } from 'moment'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import Period from '@/common/models/periods/period'
import isEqual from 'lodash/isEqual'

@Component
export default class PeriodInput extends Vue {

  @Prop({ required: true })
  private value!: Period

  @Prop({ type: Boolean, default: false })
  private sameDay!: boolean

  @Prop({ default: 'Beginn am' })
  private beginDateLabel!: string

  @Prop({ default: 'Ende am' })
  private endDateLabel!: string

  // @Prop({ default: 'DD.MM.YYYY' })
  // private dateFormat!: string

  // @Prop({ default: 'HH:mm' })
  // private timeFormat!: string

  private beginDate: string = this.getBeginDate(this.value)
  private beginTime: string = this.getBeginTime(this.value)
  private endDate: string = this.getEndDate(this.value)
  private endTime: string = this.getEndTime(this.value)

  @Watch('value')
  private valueChanged(val: Period, old: Period) {
    if (isEqual(val, old) === false) {
      this.beginDate = this.getBeginDate(val)
      this.beginTime = this.getBeginTime(val)
      this.endDate = this.getEndDate(val)
      this.endTime = this.getEndTime(val)
    }
  }

  @Watch('beginDate')
  private beginDateChanged(val: string, old: string) {
    if (val !== old) {
      this.emit()
    }
  }

  private beginTimeChanged() {
    this.beginTime = moment(this.beginTime, moment.HTML5_FMT.TIME).format('HH:mm')
    this.emit()
  }

  @Watch('endDate')
  private endDateChanged(val: string, old: string) {
    if (val !== old) {
      this.emit()
    }
  }

  private endTimeChanged() {
    this.endTime = moment(this.endTime, moment.HTML5_FMT.TIME).format('HH:mm')
    this.emit()
  }

  private emit() {
    const p = new Period()
    p.begin = `${this.beginDate}T${this.beginTime}`
    if (this.sameDay) {
      p.end = `${this.beginDate}T${this.endTime}`
    } else {
      p.end = `${this.endDate}T${this.endTime}`
    }
    console.log(p)
    this.$emit('input', p)
  }

  private getBeginDate(p: Period) {
    return this.getDate(p.begin)
  }

  private getBeginTime(p: Period) {
    return this.getTime(p.begin)
  }

  private getEndDate(p: Period) {
    return this.getDate(p.end)
  }

  private getEndTime(p: Period) {
    return this.getTime(p.end)
  }

  private getDate(d: string) {
    return moment(d, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD')
  }

  private getTime(d: string) {
    return moment(d, 'YYYY-MM-DDTHH:mm').format('HH:mm')
  }
}
