import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import PermissionAssignment from '@/users/entities/permission-assignment'
import Permission from '@/users//entities/permission'

class PermissionsService {

  public async findAllAsync() {
    const res = await axios.get<PermissionAssignment>('permissions')
    return Responses.obj<PermissionAssignment>(res)
  }

  public async createAsync(entity: Permission) {
    const res = await axios.post<Permission>(`permissions/create`, entity)
    return Responses.obj(res)
  }

  public async updateAsync(entity: Permission) {
    const res = await axios.put<Permission>(`permissions/${entity.id}/update`, entity)
    return Responses.obj(res)
  }

  public async addAssignmentAsync(role: string, permission: string) {
    await axios.post<any>(`permissions/${role}/add/${permission}`)
  }

  public async removeAssignmentAsync(role: string, permission: string) {
    await axios.delete<any>(`permissions/${role}/remove/${permission}`)
  }
}

export default new PermissionsService()
