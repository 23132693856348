import RegistrationRequest from '@/users/requests/registration-request'
// import accountService from '@/users/services/account-service'
import { Component, Vue, Ref } from 'vue-property-decorator'
import AddressInput from '../../../locations/components/address-input/address-input';

@Component
export default class RegisterForm extends Vue {

  @Ref()
  private validator!: ValidationObserver

  @Ref()
  private addressInput!: AddressInput

  private valid = true
  private request = new RegistrationRequest()
  private loading = false

  private roles = [
    { text: 'Admin', value: 'ADMIN' },
    { text: 'Tenant', value: 'TENANT' },
    { text: 'Benutzer', value: 'USER' },
  ]

  public async register() {
    this.loading = true
    if (await this.validator.validate()) {
      await this.addressInput.locate()
      this.$emit('register', this.request)
    }
  }

  public reset() {
    this.loading = false
  }
}
