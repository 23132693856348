import Guid from '@/app/utils/guid'
import Address from '@/locations/models/address'
import Entity from '@/common/models/entity'

export default class User extends Entity {

  public userName = ''
  public email = ''
  public tenantId = Guid.empty()
  public role = 'USER'
  public salutation = 'Mrs'
  public title = 'Mag'
  public firstName = ''
  public lastName = ''
  public companyName = ''
  public address = new Address()
  public isDeleted = false
  public remarks = ''
  public radiusOfInterest: number = 50
  public automaticNewsfeedEnabled: boolean = false
}
