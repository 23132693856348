// NOT WORKING

import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator'

@Component
export default class XEditDialog extends Vue {

  @Prop({ type: Boolean, default: false })
  private value!: boolean

  @Prop({ type: Boolean, default: false })
  private editMode!: boolean

  @Prop({ type: String, default: '' })
  private title!: boolean

  @Ref()
  private validator!: ValidationObserver

  private dialog: boolean = this.value

  @Watch('value')
  private valueChanged(val: boolean, old: boolean) {
    if (val !== old) {
      this.dialog = val
    }
  }

  @Watch('dialog')
  private dialogChanged(val: boolean, old: boolean) {
    this.$emit('input', this.dialog)
  }

  private async save() {
    this.$emit(this.editMode ? 'update' : 'save')
    this.dialog = false
  }

  private cancel() {
    this.$emit('cancel')
    this.dialog = false
  }
}
