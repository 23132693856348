var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"800px","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card',{staticClass:"d-flex flex-column",attrs:{"min-height":"100%"}},[_c('v-card-title',[(_vm.editMode)?_c('span',[_vm._v("Mandanten bearbeiten")]):_c('span',[_vm._v("Mandanten hinzufügen")])]),_c('v-card-text',{staticClass:"flex-grow-1"},[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Vorname","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.firstName),callback:function ($$v) {_vm.$set(_vm.model, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nachname","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.lastName),callback:function ($$v) {_vm.$set(_vm.model, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model.lastName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-mail","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Telefon","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model.phone"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Firma","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"vat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"UID","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.model.vat),callback:function ($$v) {_vm.$set(_vm.model, "vat", $$v)},expression:"model.vat"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('address-input',{ref:"addressInput",attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-7 pb-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"disabled":!valid,"loading":_vm.loading,"depressed":"","color":"primary"},on:{"click":_vm.confirm}},[(_vm.editMode)?_c('span',[_vm._v("Übernehmen")]):_c('span',[_vm._v("Erstellen")])])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }