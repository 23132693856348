// TODO: Move folder to models.
import moment from 'moment'

export default class Period {

  /**
   * Begin date and time. String format has to be YYYY-MM-DD HH:mm
   */
  public begin: string = moment().format('YYYY-MM-DDTHH:mm')

  /**
   * End date and time. String format has to be YYYY-MM-DD HH:mm
   */
  public end: string = moment().format('YYYY-MM-DDTHH:mm')
}
