import {
  Component,
  Mixins,
  Prop,
  Vue,
  Watch,
  Ref,
} from "vue-property-decorator"
import Inquiry from "@/inquiries/models/inquiry"
import EditDialogMixin from "@/common/dialogs/mixins/edit-dialog-mixin"
import AddressInput from "@/locations/components/address-input/address-input"
import { has } from "lodash"
import moment, { Moment } from "moment"

@Component
export default class InquiryDialog extends Mixins<EditDialogMixin<Inquiry>>(
  EditDialogMixin
) {
  // NOTE: Required to guarantee reactivity of the model.
  protected model: Inquiry = new Inquiry()
  errors: string[] = []

  @Ref()
  private addressInput!: AddressInput

  protected beforeConfirm() {
    // Wenn es sich um einen ortsgebundenen Auftrag handelt, setze das
    // Ablaufdatum gleich Beginndatum.
    if (this.model.hasLocation) {
      this.model.expiryDate = this.model.period.begin
      if (this.model.period.end < this.model.period.begin) {
        this.model.period.end = this.model.period.begin
      }
    }
  }

  protected async afterValidationSuccess() {
    if (this.model.hasLocation) {
      await this.addressInput.locate()
    }
  }

  async validate(): Promise<Boolean> {
    this.errors = []
    let hasAmount = this.model.pricing.amount > 0
    if (!hasAmount) {
      this.errors.push("Bitte geben Sie einen Betrag an.")
    }
    if (this.model.hasLocation) {
      let periodBeginInFuture = moment(this.model.period.begin) > moment()
      if (!periodBeginInFuture) {
        this.errors.push("Der Verhandlungsbeginn muss in der Zukunft liegen.")
      }
      let periodEndAfterBegin = moment(this.model.period.end) > moment(this.model.period.begin)
      if (!periodEndAfterBegin) {
        this.errors.push(
          "Der Verhandlungsende muss hinter dem Verhandlungsbeginn liegen."
        )
      }
      return hasAmount && periodBeginInFuture && periodEndAfterBegin
    } else {
      let expiryInFuture = moment(this.model.expiryDate) >= moment().startOf('day')
      if (!expiryInFuture) {
        this.errors.push("Das Erledigungsdatum muss in der Zukunft liegen.")
      }
      return hasAmount && expiryInFuture
    }
  }
}
