import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component
export default class SidebarLayout extends Vue {

  @Prop()
  private title!: string

  @Prop({ default: 'simple-header' })
  private header!: string

  @Prop({ default: true })
  private showHeader!: boolean

  private showSideMenu = false

  public toggleSideMenu() {
    this.showSideMenu = !this.showSideMenu
    this.$bus.$emit("sidemenu:toggle")
  }

  @Watch('$route', { immediate: true, deep: true })
  private async onUrlChangeAsync(newVal: any) {
    this.showSideMenu = this.$vuetify.breakpoint.lgAndUp
  }
}
