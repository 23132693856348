import moment, { Moment } from 'moment'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component
export default class DateInput extends Vue {

  @Ref()
  private pickerMenu!: any

  @Prop({ required: true })
  private value!: moment.MomentInput

  @Prop({ default: 'DD.MM.YYYY' })
  private displayFormat!: string

  @Prop({ default: 'YYYY-MM-DD' })
  private modelFormat!: string

  @Prop({ default: '' })
  private name!: string

  @Prop({ default: '' })
  private rules!: string

  @Prop({ default: () => [] })
  private errors!: any[]

  private menu = false
  private model = this.formatDate(this.value)
  private displayModel = this.formatDisplayDate(this.value)

  @Watch('value')
  private valueChanged(val: moment.MomentInput) {
    this.model = this.formatDate(val)
    this.displayModel = this.formatDisplayDate(val)
  }

  private dateSelected() {
    this.pickerMenu.save(this.model)
    this.$emit('input', this.model)
  }

  private formatDate(val: moment.MomentInput) {
    return moment(val, this.modelFormat).format(this.modelFormat)
  }

  private formatDisplayDate(val: moment.MomentInput) {
    return moment(val, this.modelFormat).format(this.displayFormat)
  }
}
