import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import Inquiry from '@/inquiries/models/inquiry'
import { InquiryState } from '@/inquiries/models/inquiry-state'
import InquiriesFilterRequest from '@/inquiries/models/inquiries-filter-request'
import PaginationResponse from '@/common/pagination/entities/pagination-response'
import InquiryContact from '../models/Inquiry-contact'

class InquiriesService {

  public async findAllAsync(request: InquiriesFilterRequest) {
    const res = await axios.post<PaginationResponse<Inquiry>>('inquiries', request)
    return Responses.pagedList(res)
  }

  public async findAllClosedAsync(request: InquiriesFilterRequest) {
    const res = await axios.post<PaginationResponse<Inquiry>>('inquiries/closed', request)
    return Responses.pagedList(res)
  }

  public async findAllPendingContactsAsync() {
    const res = await axios.get<InquiryContact[]>('inquiries/pendingContacts')
    return Responses.list(res)
  }

  public async saveAsync(inquiry: Inquiry) {
    const res = await axios.post<Inquiry>(`inquiries/create`, inquiry)
    return Responses.obj(res)
  }

  public async updateAsync(inquiry: Inquiry) {
    const res = await axios.put<Inquiry>(`inquiries/${inquiry.id}/update`, inquiry)
    return Responses.obj(res)
  }

  public async removeAsync(id: string) {
    await axios.delete(`inquiries/${id}/delete`)
  }

  public async contactAsync(takerId: string, inquiry: Inquiry) {
    await axios.post<Inquiry>(`inquiries/${takerId}/contact/${inquiry.id}`)
  }

  public async acceptContactAsync(takerId: string, inquiry: Inquiry) {
    await axios.post(`inquiries/${takerId}/acceptContact/${inquiry.id}`)
  }

  public async closeAsync(inquiry: Inquiry) {
    await axios.post<Inquiry>(`inquiries/${inquiry.id}/close`)
  }

  public async reactivateAsync(inquiry: Inquiry) {
    await axios.post<Inquiry>(`inquiries/${inquiry.id}/reactivate`)
  }
}

export default new InquiriesService()
