var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('h1',{staticClass:"mb-5"},[_vm._v("Registrieren")]),_c('p',[_vm._v("Bitte füllen Sie untenstehende Daten vollständig aus.")]),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-Mail","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.request.email),callback:function ($$v) {_vm.$set(_vm.request, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"request.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('salutation-select',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(_vm.request.salutation),callback:function ($$v) {_vm.$set(_vm.request, "salutation", $$v)},expression:"request.salutation"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('academic-title-select',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(_vm.request.title),callback:function ($$v) {_vm.$set(_vm.request, "title", $$v)},expression:"request.title"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Vorname","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.request.firstName),callback:function ($$v) {_vm.$set(_vm.request, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"request.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nachname","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.request.lastName),callback:function ($$v) {_vm.$set(_vm.request, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"request.lastName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"companyName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Firmenwortlaut (optional)","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.request.companyName),callback:function ($$v) {_vm.$set(_vm.request, "companyName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"request.companyName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('address-input',{ref:"addressInput",attrs:{"outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.request.address),callback:function ($$v) {_vm.$set(_vm.request, "address", $$v)},expression:"request.address"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":{ required: true, regex: /^[SPJR][0-9]{6}$/ },"name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Anwaltscode","outlined":"","dense":"","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.request.code),callback:function ($$v) {_vm.$set(_vm.request, "code", $$v)},expression:"request.code"}})]}}],null,true)}),_c('div',{staticStyle:{"padding":"5px"}},[_c('small',[_vm._v("Der Anwaltscode wird nur benötigt um sicherzustellen, dass ausschließlich Anwälte dieses System nutzen.")])])],1)],1),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":!valid,"depressed":"","color":"primary"},on:{"click":_vm.register}},[_vm._v("Registrieren")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }