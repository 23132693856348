import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import CrudService from '@/common/services/crud-service'
import User from '@/users/entities/user'
import RegistrationRequest from '@/users/requests/registration-request'
import RegistrationResponse from '@/users/responses/registration-response'
import store from '@/app/store'
import AccountModule from '@/users/store/account-module'
import { getModule } from 'vuex-module-decorators'

class AccountService extends CrudService<User> {

  public async registerAsync(request: RegistrationRequest) {
    const res = await axios.post<RegistrationResponse>('account/register', request)
    return Responses.obj(res)
  }

  public async confirmEmailAsync(userId: string, token: string) {
    const res = await axios.post(`account/${userId}/email/confirm`, {
      userId: userId,
      token: token
    })
    return Responses.obj(res)
  }

  public async enableAsync(user: User) {
    await axios.post(`account/${user.id}/enable`)
  }

  public async disableAsync(user: User) {
    await axios.post(`account/${user.id}/disable`)
  }

  public async getCurrent() {
    const accountModule = getModule(AccountModule, store)
    const url = `account/current`
    const res = await axios.get<User>(url)
    const data = Responses.obj(res)
    accountModule.setUserData(data)
    return data
  }

  public async updateCurrent(model: User) {
    const accountModule = getModule(AccountModule, store)
    const res = await axios.put<User>(`account/current/update`, model)
    const data = Responses.obj(res)
    accountModule.setUserData(model)
    return data
  }
}

export default new AccountService('account')
