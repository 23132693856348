import PagedMixin from '@/common/pagination/mixins/paged-mixin'
import User from '@/users/entities/user'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class UsersTable extends Mixins(PagedMixin) {

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public users!: User[]

  get headers() {
    return [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'E-Mail', value: 'email', sortable: false },
      { text: 'Adresse', value: 'address', sortable: false },
      { text: 'Benutzerrolle', value: 'role', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: '', value: 'action', sortable: false, class: 'td-w-shrink', align: 'end'},
    ]
  }

  private edit(user: User) {
    this.$emit('edit', user)
  }

  private impersonate(user: User) {
    this.$emit('impersonate', user)
  }

  private enable(user: User) {
    this.$emit('enable', user)
  }

  private disable(user: User) {
    this.$emit('disable', user)
  }

  private deleteUser(user: User) {
    if (confirm('Soll der Benutzer wirklich gelöscht werden?')) {
      this.$emit('delete', user)
    }
  }
}
