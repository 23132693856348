import Vue from 'vue'

Vue.filter('ellipsis', (v: string, maxLen: number) => {
  if (!v) {
    return ''
  }
  if (v.length <= maxLen) {
    return v
  }
  return `${v.substr(0, maxLen - 3)}...`
})
