import PaginationRequest from '@/common/pagination/entities/pagination-request'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class PagedMixin extends Vue {

  @Prop({ default: () => new PaginationRequest() })
  public pagination!: PaginationRequest

  public pPagination: PaginationRequest = this.pagination

  @Watch('pPagination', { deep: true })
  private pPaginationChanged(newVal: PaginationRequest, oldVal: PaginationRequest) {
    if (newVal !== oldVal) {
      this.$emit('update:pagination', this.pPagination)
    }
  }
}
