import { Theme } from "vuetify/types/services/theme";

const theme: Partial<Theme> = {
  dark: false,
  themes: {
    // @ts-ignore
    light: {
      primary: '#131E43',
      secondary: '#E0E1DD',
      accent: '#131E43',
      // error: '#D3AFA5',
      // warning: '#F1EAC0',
      // info: '#CDD7D9', //'#CDD7D9',
      // success: '#D5DCD1',
    },
    // @ts-ignore
    dark: {
      primary: '#131E43',
      secondary: '#E0E1DD',
      accent: '#B2B4B3',
      // error: '#D3AFA5',
      // warning: '#F1EAC0',
      // info: '#CDD7D9',
      // success: '#D5DCD1',
    },
  },
}

export default theme
