import bus from '@/app/bus'
import Vue from 'vue'
import { PluginObject, VueConstructor } from 'vue'

declare module 'vue/types/vue' {
    interface Vue {
        $bus: Vue
    }
}

class VueBusPlugin implements PluginObject<any> {

    public install(vue: VueConstructor, options?: any): void {

        vue.prototype.$bus = bus
    }
}

Vue.use(new VueBusPlugin())
