import Vue, { VNode } from 'vue'
import { DirectiveBinding } from 'vue/types/options'
import debounce from 'lodash/debounce'

Vue.directive('debounce', {
  bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    if (binding.arg === undefined) {
      throw new Error('missing argument to v-debounce.')
    }

    if (vnode === null) {
      return
    }

    const instance = vnode.componentInstance
    if (instance === undefined) {
      return
    }

    const listeners = instance.$listeners[binding.arg]

    instance.$off(binding.arg)

    if (Array.isArray(listeners)) {
      for (const listener of listeners) {
        wrapListener(instance, listener, binding)
      }
    } else {
      wrapListener(instance, listeners, binding)
    }
  },
})

function wrapListener(instance: Vue, listener: Function, binding: DirectiveBinding) {
  if (binding.arg === undefined) {
    throw new Error('missing argument to v-debounce.')
  }

  const name = 'debounced' + binding.arg
  instance.$on(binding.arg, function() {
    if (instance.$data[name]) {
      instance.$data[name].cancel()
    }
    const wrapper = () => {
      // @ts-ignore
      listener.call(this, ...arguments)
    }
    const debounced = debounce(wrapper, binding.value)
    instance.$data[name] = debounced
    debounced()
  })
}
