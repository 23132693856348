import PaginationRequest from '@/common/pagination/entities/pagination-request'
import { InquiryState } from '@/inquiries/models/inquiry-state'

export default class InquiriesFilterRequest {
  public creatorId: string | null = null
  public takerId: string | null = null
  public state: InquiryState | null = InquiryState.Open
  public search: string = ''
  public pagination: PaginationRequest = new PaginationRequest()
  public radiusOfInterest: number = 50
  public includeContacts: boolean = false
}