import Tenant from '@/tenants/entities/tenant'
import { Component, Mixins, Prop, Vue, Ref } from 'vue-property-decorator'
import EditDialogMixin from '@/common/dialogs/mixins/edit-dialog-mixin'
import AddressInput from '@/locations/components/address-input/address-input'

@Component
export default class TenantDialog extends Mixins<EditDialogMixin<Tenant>>(EditDialogMixin) {

  @Ref()
  private addressInput!: AddressInput

  protected async afterValidationSuccess() {
    return this.addressInput.locate()
  }
}
