var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fill-height":""}},[_c('v-flex',[_c('v-layout',{attrs:{"align-center":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs11":"","md6":"","lg4":""}},[_c('h2',{staticClass:"text-center"},[_vm._v("Willkommen bei")]),_c('h1',{staticClass:"text-center mb-10"},[_vm._v(_vm._s(_vm.$env.VUE_APP_TITLE))]),_c('v-form',{staticClass:"elevation-10 pa-5 mx-sm-10",on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword($event)}}},[_c('h3',{staticClass:"mb-5"},[_vm._v("Passwort zurücksetzen")]),_c('v-alert',{attrs:{"type":"error","value":_vm.serverError !== ''}},[_vm._v(" "+_vm._s(_vm.serverError)+" ")]),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.email,"label":"E-Mail","outlined":"","disabled":"","error-messages":errors,"hide-details":errors.length === 0}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Passwort","type":"password","outlined":"","error-messages":errors,"hide-details":errors.length === 0,"autocomplete":"off","autocorrect":"off","autocapitalize":"none","spellcheck":"false"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"required|confirmed:password","name":"passwordConfirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Passwort bestätigen","type":"password","outlined":"","error-messages":errors,"hide-details":errors.length === 0,"autocomplete":"off","autocorrect":"off","autocapitalize":"none","spellcheck":"false"},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"type":"submit","loading":_vm.loggingIn,"disabled":!valid,"depressed":"","color":"primary"}},[_vm._v("Zurücksetzen")])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }