import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/src/locale/de'
import icons from '@/assets/icons'
import theme from '@/assets/theme'

Vue.use(Vuetify)

export default new Vuetify({
  icons,
  theme,
  lang: {
    locales: { de },
    current: 'de',
  },
})
