import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class XConfirmDialog extends Vue {

  @Prop()
  private title!: string

  @Prop({ default: 'Bestätigen' })
  private confirmButtonText!: string

  private dialog: boolean = false
  private context: object = {}

  public open(context: object = {}) {
    this.context = context || {}
    this.dialog = true
  }

  private confirm() {
    this.$emit('confirm', this.context)
    this.dialog = false
  }

  private cancel() {
    this.$emit('cancel')
    this.dialog = false
  }
}
