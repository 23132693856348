import { Component, Vue, Watch } from "vue-property-decorator"
import moment from "moment"
import authService from "@/users/services/auth-service"
import accountService from "@/users/services/account-service"

@Component
export default class App extends Vue {
  // TODO: Scroll Funktionalität wird vermutlich in mehreren layouts benötigt.
  // private showScrollButton: boolean = false
  private snacks: boolean = false
  private dialog: boolean = false
  private color: string = "info"
  private msg: string = ""

  get layout() {
    return this.$route.meta.layout || "empty-layout"
  }

  get coyrightYear() {
    return moment().year()
  }

  // Hier können vorab Daten in den Store geladen werden, die sich für die
  // Dauer der Session eher selten ändern.
  private async prefetch() {
    // if (this.$auth.isAdmin === false) {
    // }
  }

  // private onScroll(e: any) {
  //   this.showScrollButton = e.target.scrollingElement.scrollTop > 50
  // }

  // private moveToTop() {
  //   this.$vuetify.goTo('#header')
  // }

  private showSnacks(color: string, msg: string) {
    this.color = color
    this.msg = msg
    this.snacks = true
  }

  private showExceptionDialog(msg: string) {
    this.msg = msg
    this.dialog = true
  }

  private created() {
    this.$bus.$on("app.snack.show", this.showSnacks)
    this.$bus.$on("app.exception-dialog.show", this.showExceptionDialog)
    this.$bus.$on("auth.logged-in", this.prefetch)
    this.$bus.$on("auth.logged-in", accountService.getCurrent)

    // check every minute if login expired. if yes, redirect to login
    setInterval(async () => {
      if (this.$route.meta.requiresAuth && authService.authenticated()) {
        const data = await accountService.getCurrent()
        if (data == null) {
          await authService.logoutAsync()
        }
      }
    }, 1000 * 60)
  }

  private async mounted() {
    if (this.$route.meta.requiresAuth && authService.authenticated()) {
      const data = await accountService.getCurrent()
      if (data == null) {
        await authService.logoutAsync()
      }
    }
  }
}
