import Vue from "vue";
import { localize, configure } from "vee-validate";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  required,
  required_if,
  email,
  confirmed,
  min,
  numeric,
  min_value,
  regex,
  length
} from "vee-validate/dist/rules";
import moment from "moment";

Vue.component("validation-observer", ValidationObserver);
Vue.component("validation-provider", ValidationProvider);

// TODO: Eigentlich würde man das JSON importieren aber TS weigert sich das JSON zu akzeptieren.
//       Eventuell hilft ein ts-shim.
// import de from 'vee-validate/dist/locale/de.json'

const dict = {
  de: {
    names: {
      name: "Name",
      title: "Titel",
      description: "Beschreibung",
      email: "E-Mail",
      phone: "Telefon",
      company: "Firma",
      vat: "Steuernummer",
      addressLine: "Adresse",
      postalCode: "PLZ",
      city: "Ort",
      country: "Land",
      password: "Passwort",
      passwordConfirmation: "Passwortbestätigung",
      firstName: "Vorname",
      lastName: "Nachname",
      companyName: "Firmenwortlaut",
      code: "Anwaltscode",
      radiusOfInterest: "Suchradius",
      pricing: "Betrag"
    },
    messages: {
      alpha: "{_field_} darf nur alphabetische Zeichen enthalten",
      alpha_dash:
        "{_field_} darf alphanumerische Zeichen sowie Striche und Unterstriche enthalten",
      alpha_num: "{_field_} darf nur alphanumerische Zeichen enthalten",
      alpha_spaces:
        "{_field_} darf nur alphanumerische Zeichen und Leerzeichen enthalten",
      between: "{_field_} muss zwischen {min} und {max} liegen",
      confirmed: "Der Wert von {_field_} stimmt nicht überein",
      digits:
        "{_field_} muss numerisch sein und exakt {length} Ziffern enthalten",
      dimensions: "{_field_} muss {width} x {height} Bildpunkte groß sein",
      email: "{_field_} muss eine gültige E-Mail-Adresse sein",
      excluded: "{_field_} muss ein gültiger Wert sein",
      ext: "{_field_} muss eine gültige Datei sein",
      image: "{_field_} muss eine Grafik sein",
      oneOf: "{_field_} muss ein gültiger Wert sein",
      integer: "{_field_} muss eine ganze Zahl sein",
      length: "Die Länge von {_field_} muss {length} sein",
      max: "{_field_} darf nicht länger als {length} Zeichen sein",
      max_value: "{_field_} darf maximal {max} sein",
      mimes: "{_field_} muss einen gültigen Dateityp haben",
      min: "{_field_} muss mindestens {length} Zeichen lang sein",
      min_value: "{_field_} muss mindestens {min} sein",
      numeric: "{_field_} darf nur numerische Zeichen enthalten",
      regex: "Das Format von {_field_} ist ungültig",
      required: "{_field_} ist ein Pflichtfeld",
      required_if: "{_field_} ist ein Pflichtfeld",
      size: "{_field_} muss kleiner als {size}KB sein",
    },
  },
};

// configure({})

extend("required", required);
extend("required_if", required_if);
extend("email", email);
extend("confirmed", confirmed);
extend("numeric", numeric);
extend("min", min);
extend("min_value", min_value);
extend("regex", regex);
extend("length", length);

extend("daterange", {
  params: ["start"],
  // @ts-ignore
  validate(value, { start }) {
    if (
      moment(moment(value).format("YYYY-MM-DD")).isAfter(
        moment(moment(start).format("YYYY-MM-DD"))
      )
    ) {
      return true;
    }
    return false;
  },
  message: "This field value must be after {target}",
});

extend("timerange", {
  params: ["start"],
  // @ts-ignore
  validate(value, { start }) {
    if (
      moment(moment(value).format("HH:mm")).isAfter(
        moment(moment(start).format("HH:mm"))
      )
    ) {
      return true;
    }
    return false;
  },
  message: "This field value must be after {target}",
});

extend("infuture", {
  params: ["start"],
  // @ts-ignore
  validate(value, { start }) {
    if (moment(value).isAfter(moment().add(1, "hour"))) {
      return true;
    }
    return false;
  },
  message: "This field value must be after {target}",
});

// NOTE: Add more validation rules here.

// Installs the translations provided by vee-validate.
// Sets the default translation.
localize("de", dict.de);
