import PagedMixin from '@/common/pagination/mixins/paged-mixin'
import Tenant from '@/tenants/entities/tenant'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class TenantsTable extends Mixins(PagedMixin) {

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ default: () => [] })
  public tenants!: Tenant[]

  public options: any = {}

  get headers() {
    return [
      { text: 'Firma', value: 'company', sortable: false },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'E-Mail', value: 'email', sortable: false },
      { text: '', value: 'action', sortable: false, class: 'td-w-shrink', align: 'end'},
    ]
  }

  private edit(tenant: Tenant) {
    this.$emit('edit', tenant)
  }
}
