import Home from '@/app/views/home.vue'
import AGB from '@/app/views/agb.vue'
import DSGVO from '@/app/views/dsgvo.vue'
import FAQ from '@/app/views/faq.vue'
import About from '@/app/views/about.vue'
import Impressum from '@/app/views/impressum.vue'

import Tenants from '@/tenants/views/tenants.vue'

import EmailConfirm from '@/users/views/email-confirm/email-confirm.vue'
import Login from '@/users/views/login/login.vue'
import Permissions from '@/users/views/permissions/permissions.vue'
import Register from '@/users/views/register/register.vue'
import SetPassword from '@/users/views/set-password/set-password.vue'
import ResetPassword from '@/users/views/reset-password/reset-password.vue'
import Settings from '@/users/views/settings/settings.vue'
import Users from '@/users/views/users/users.vue'

import Demo from '@/common/views/demo.vue'

import Error from '@/app/views/error.vue'

import Examples from '@/app/views/examples.vue'

import InquiriesSearch from '@/inquiries/views/inquiries-search/inquiries-search.vue'
import InquiriesMine from '@/inquiries/views/inquiries-mine/inquiries-mine.vue'
import InquiriesContacts from '@/inquiries/views/inquiries-contacts/inquiries-contacts.vue'
import InquiriesClosed from '@/inquiries/views/inquiries-closed/inquiries-closed.vue'

export default {

  // Default route the user will be redirected to if none is specified in the
  // request params.
  loginDefaultRedirect: {
    ADMIN: '/tenants',
    TENANT: '/inquiries-search',
    USER: '/inquiries-search',
    DEFAULT: '/',
  },

  // Route to redirect to after a successful logout.
  logoutRedirect: '/login',

  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/agb',
      name: 'agb',
      component: AGB,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: FAQ,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/dsgvo',
      name: 'dsvgo',
      component: DSGVO,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/impressum',
      name: 'impressum',
      component: Impressum,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/tenants',
      name: 'tenants',
      component: Tenants,
      meta: {
        layout: 'sidebar-layout',
        title: 'Mandanten',
        requiresAuth: true,
        roles: ['ADMIN'],
      },
    },
    {
      path: '/:user/email-confirm/:token',
      name: 'email-confirm',
      component: EmailConfirm,
      props: true,
      meta: {
        layout: 'empty-layout',
      },
    },
    {
      path: '/login/:redirectUrl?',
      name: 'login',
      component: Login,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/permissions',
      name: 'permissions',
      component: Permissions,
      meta: {
        layout: 'sidebar-layout',
        title: 'Berechtigungen',
        requiresAuth: true,
        roles: ['ADMIN'],
      },
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        layout: 'header-layout',
      },
    },
    {
      path: '/:email/set-password/:token',
      name: 'set-password',
      component: SetPassword,
      props: true,
      meta: {
        layout: 'empty-layout',
      },
    },
    {
      path: '/:email/reset-password/:token',
      name: 'reset-password',
      component: ResetPassword,
      props: true,
      meta: {
        layout: 'empty-layout',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        layout: 'sidebar-layout',
        title: 'Einstellungen',
        requiresAuth: true,
      },
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      meta: {
        layout: 'sidebar-layout',
        title: 'Benutzer',
        requiresAuth: true,
        roles: ['ADMIN', 'TENANT'],
      },
    },

    {
      path: '/demo',
      name: 'demo',
      component: Demo,
      meta: {
        layout: 'sidebar-layout',
      },
    },

    {
      path: '/examples',
      name: 'examples',
      component: Examples,
      meta: {
        layout: 'header-layout',
      },
    },

    {
      path: '/inquiries-search',
      name: 'inquiries-search',
      component: InquiriesSearch,
      meta: {
        layout: 'sidebar-layout',
        title: 'Offene Gesuche',
        requiresAuth: true,
        roles: ['TENANT', 'USER'],
      },
    },
    {
      path: '/inquiries-closed',
      name: 'inquiries-closed',
      component: InquiriesClosed,
      meta: {
        layout: 'sidebar-layout',
        title: 'Abgeschlossene Aufträge',
        requiresAuth: true,
        roles: ['TENANT'],
      },
    },
    {
      path: '/inquiries-mine',
      name: 'inquiries-mine',
      component: InquiriesMine,
      meta: {
        layout: 'sidebar-layout',
        title: 'Meine Substitutionsgesuche',
        requiresAuth: true,
        roles: ['TENANT', 'USER'],
      },
    },
    {
      path: '/inquiries-contacts',
      name: 'inquiries-contacts',
      component: InquiriesContacts,
      meta: {
        layout: 'sidebar-layout',
        title: 'Angebote zur Auftragsübernahme',
        requiresAuth: true,
        roles: ['TENANT', 'USER'],
      },
    },

    // Error page.
    {
      path: '/error/:status',
      name: 'error',
      component: Error,
      meta: {
        layout: 'empty-layout',
      },
    },

    // Fallback redirect.
    { path: '*', redirect: '/error/404' },
  ],
}
