import Period from '@/common/models/periods/period'
import moment, { Moment } from 'moment'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component
export default class DateNav extends Vue {

  @Prop({ required: true })
  private value!: Period

  private period = [this.value.begin, this.value.end].sort()
  private focusDatePicker = false

  get title() {
    if (this.period) {
      // Sort to be sure that period[0] < period[1].
      this.period = this.period.sort()
      const begin = moment(this.period[0]).format('DD.MM.YYYY')
      const end = moment(this.period[1]).format('DD.MM.YYYY')
      return `${begin} - ${end}`
    }
    return ''
  }

  @Watch('value', { deep: true })
  private valueChanged(val: Period, old: Period) {
    if (val.begin !== old.begin || val.end !== old.end) {
      this.period = [val.begin, val.end].sort()
    }
  }

  private onChange() {
    const p = this.getPeriod()
    this.$emit('input', p)
    this.$emit('input:pick', p)
  }

  private prev() {
    const begin = moment(this.period[0])
    const end = moment(this.period[1])
    const step = end.diff(begin, 'days') + 1

    this.period[0] = begin.subtract(step, 'days').format('YYYY-MM-DD')
    this.period[1] = end.subtract(step, 'days').format('YYYY-MM-DD')

    const p = this.getPeriod()
    this.$emit('input', p)
    this.$emit('input:prev', p)
  }

  private next() {
    const begin = moment(this.period[0])
    const end = moment(this.period[1])
    const step = end.diff(begin, 'days') + 1

    this.period[0] = begin.add(step, 'days').format('YYYY-MM-DD')
    this.period[1] = end.add(step, 'days').format('YYYY-MM-DD')

    const p = this.getPeriod()
    this.$emit('input', p)
    this.$emit('input:next', p)
  }

  private getPeriod() {
    const p = new Period()
    // Sort to be sure that period[0] < period[1].
    this.period = this.period.sort()
    p.begin = this.period[0]
    p.end = this.period[1]
    return p
  }

}
